import {
  Badge,
  Button,
  Tooltip,
  ListGroup,
  Accordion,
  OverlayTrigger
} from "react-bootstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  faEye,
  faSync,
  faBoxArchive,
  faCheckCircle,
  faTrashCanArrowUp,
  faCircleExclamation,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableRow,
  CustomCard,
  SubTextDiv,
  EllipsisDiv,
  TableDetailCell,
  FunnelTypeBadge,
  FunnelNameColumn,
  TableDetailNameCell
} from "../common-styling";
import Drawer from "../drawer";
import Loader from "../loader/loader";
import {
  generateDateTime,
  generateContactCloudUrl
} from "../../services/common-service";
import {
  resetLead,
  getLeadById,
  archiveLead,
  getLeadActivities
} from "../../services/api-declaration";
import LeadActivities from "./lead-activities";
import CardDataContainer from "./card-container";
import { useDialog } from "../../hooks/useDialog";
import NoResultsRow from "../no-results-table-row";
import { countryOptions } from "../common-confgs/common-vars";
import ForwardedStatusOverlay from "./forwarded-status-overlay";
import { INTEGRATION_TYPE, LEAD_LOGS_STATUS_TYPE } from "../strings";
import { showError, showSuccess } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const LeadsTableRow = ({ leads, fetchLeads }) => {
  const { t } = useTranslation();
  const [leadFunnel, setLeadFunnel] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerDetails, setDrawerDetails] = useState(null);
  const [isLoadingLead, setIsLoadingLead] = useState(false);
  const [activities, setActivities] = useState([]);
  const { SUCCESS, FAIL } = LEAD_LOGS_STATUS_TYPE;
  const {
    showSyncLeadDialogBox,
    showConfirmationDialogBox,
    setDependentStates
  } = useDialog();

  const toggleDrawer = () => setShowDrawer(!showDrawer);
  const handleDrawer = async (_leadId) => {
    const filteredRow = leads.find((lead) => lead._id === _leadId);
    setLeadFunnel(filteredRow.funnelId);
    toggleDrawer();
    setIsLoadingLead(true);
    try {
      const response = await getLeadById(_leadId);
      setDrawerDetails(response.data.data);
      await fetchLeadActivities(_leadId);
    } catch (err) {
      showError(
        err.response?.data?.message ||
          t("toastMessage.errorInGetting", { what: "lead" })
      );
    } finally {
      setIsLoadingLead(false);
    }
  };
  const checkStatus = (item) => item.status === FAIL; // syncData(virtual property) status.
  const handleStatusIcon = (syncData) => {
    const isStatusFailed = syncData.some(checkStatus);
    // check if any integration's status is failed then show failed icon
    if (isStatusFailed) {
      return (
        <FontAwesomeIcon className="text-danger" icon={faCircleExclamation} />
      );
    } else {
      return <FontAwesomeIcon className="text-success" icon={faCheckCircle} />;
    }
  };
  const toggleSyncDialog = (lead) =>
    showSyncLeadDialogBox({
      leadInfo: lead,
      fetchLeads
    });

  const toggleConfirmationDialog = (selectedLead) =>
    showConfirmationDialogBox({
      dialogMessage: renderArchiveDialogMessage(selectedLead),
      dialogType: selectedLead?.archive ? "primary" : "danger",
      responseAction: archiveDialogResponseAction,
      title: selectedLead?.archive ? "Unarchive Lead" : "Archive Lead"
    });

  const handleArchive = async (leadId, bool) => {
    try {
      const response = await archiveLead(leadId, { archive: bool });
      showSuccess(response.data.message);
      await fetchLeads();
    } catch (err) {
      console.log("ERROR ", err);
      showError(err.response.data.message || t("toastMessage.archiveError"));
    }
  };
  const archiveDialogResponseAction = (action, values) => {
    if (action) {
      handleArchive(
        values.selectedLead._id,
        Boolean(!values.selectedLead.archive)
      );
    }
  };
  const toggleSendBackToAgentConfirmation = () =>
    showConfirmationDialogBox({
      dialogMessage: renderSendBackToAgentMessage(),
      dialogType: "primary",
      responseAction: sendBackToAgentResponseAction,
      title: t("sendBackToAgentTitle")
    });
  const renderArchiveDialogMessage = (selectedLead) => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("archiveLeadMessage", {
          archive: selectedLead?.archive
            ? t("common.unarchive")
            : t("common.archive")
        })
      }}
    ></div>
  );
  const renderSendBackToAgentMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("sendBackToAgentMessage")
      }}
    ></div>
  );
  const sendBackToAgentResponseAction = async (action) => {
    if (action && drawerDetails.id) {
      await handleResetLead(drawerDetails.id);
    }
  };
  const handleResetLead = async (leadId) => {
    try {
      const response = await resetLead(leadId);
      showSuccess(response.data.message);
      toggleDrawer();
      await fetchLeads();
    } catch (err) {
      console.log("ERROR ", err);
      showError(err.response?.data?.message);
    }
  };
  const fetchLeadActivities = async (_leadId) => {
    try {
      const response = await getLeadActivities(_leadId);
      setActivities(response.data.data);
    } catch (err) {
      console.log("ERROR ", err);
      showError(err.response?.data?.message);
    }
  };
  const isFailedToReach = (syncLog) =>
    syncLog.status === LEAD_LOGS_STATUS_TYPE.FAIL;

  const adDetailsCollection = [
    {
      label: t("campaign"),
      value: (
        <div className="border-start border-2">
          <CardDataContainer
            collection={[
              {
                label: "Id",
                value: drawerDetails?.adDetails?.campaign_id || "---"
              },
              {
                label: t("common.name"),
                value: drawerDetails?.adDetails?.campaign_name || "---"
              }
            ]}
          />
        </div>
      )
    },
    {
      label: "Adset",
      value: (
        <div className="border-start border-2">
          <CardDataContainer
            collection={[
              {
                label: "Id",
                value: drawerDetails?.adDetails?.adset_id || "---"
              },
              {
                label: t("common.name"),
                value: drawerDetails?.adDetails?.adset_name || "---"
              }
            ]}
          />
        </div>
      )
    },
    {
      label: "Ad",
      value: (
        <div className="border-start border-2">
          <CardDataContainer
            collection={[
              {
                label: "Id",
                value: drawerDetails?.adDetails?.ad_id || "---"
              },
              {
                label: t("common.name"),
                value: drawerDetails?.adDetails?.ad_name || "---"
              }
            ]}
          />
        </div>
      )
    }
  ];
  const collections = [
    {
      label: t("common.name"),
      value: drawerDetails?.leadDetails?.leadName
    },
    {
      label: t("common.email"),
      value: drawerDetails?.leadDetails?.leadEmail
    },
    {
      label: t("common.telephone"),
      value: drawerDetails?.leadDetails?.leadPhone
    },
    {
      label: t("common.postalCode"),
      value: drawerDetails?.leadDetails?.leadZip
    },
    {
      label: t("common.source"),
      value: drawerDetails?.source || "---"
    }
  ];
  const generateIntegrationType = (syncData) =>
    syncData?.integrationId?.type || syncData?.integrationType;

  return (
    <>
      {leads.length ? (
        leads.map((lead, leadIndex) => (
          <React.Fragment key={leadIndex}>
            <TableRow
              onClick={() => handleDrawer(lead._id)}
              className="cursor-pointer"
            >
              <TableDetailNameCell className="py-3">
                {lead.leadDetails.leadName}
                <SubTextDiv maxWidth={250}>
                  <small>
                    <u className="fw-bold me-1">{t("common.email")}:</u>
                    {lead.leadDetails.leadEmail}
                  </small>
                </SubTextDiv>
              </TableDetailNameCell>
              <TableDetailCell className="text-center py-3">
                {lead.syncData.length ? handleStatusIcon(lead.syncData) : "---"}
              </TableDetailCell>
              <TableDetailNameCell className="fw-normal py-3">
                {lead.funnelId ? (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>{`${t("common.type")}: ${
                          lead.funnelId.general.funnelType
                        }`}</Tooltip>
                      }
                    >
                      <FunnelNameColumn
                        funnelType={`--${lead.funnelId.general.funnelType.toLowerCase()}-color`}
                      >
                        {lead.funnelId.general.funnelName}
                      </FunnelNameColumn>
                    </OverlayTrigger>
                    <EllipsisDiv className="text-secondary">
                      {lead.funnelCustomer
                        ? `${lead.funnelCustomer.firstName} ${lead.funnelCustomer.lastName} (${lead.funnelCustomer.email})`
                        : "---"}
                    </EllipsisDiv>
                  </>
                ) : (
                  "---"
                )}
              </TableDetailNameCell>
              <TableDetailCell className="py-3 text-center">
                {(!lead.leadService || lead.leadQualified) &&
                !lead.syncData.some(isFailedToReach) ? (
                  <ForwardedStatusOverlay
                    icon="✅"
                    tooltip={t("forwardedTooltips.success")}
                  />
                ) : lead.notCallable ? (
                  <ForwardedStatusOverlay
                    icon="❌"
                    tooltip={t("forwardedTooltips.failed")}
                  />
                ) : (
                  <ForwardedStatusOverlay
                    icon="📳"
                    tooltip={t("forwardedTooltips.pending")}
                  />
                )}
              </TableDetailCell>
              <TableDetailCell className="py-3">
                {generateDateTime(lead.createdAt)}
              </TableDetailCell>
              <TableDetailCell className="py-3">
                {lead.leadDetails.leadPhone}
              </TableDetailCell>
              <TableDetailCell className="py-3">
                {lead.leadDetails.leadZip || "---"}
              </TableDetailCell>
              <TableDetailCell className="text-end py-3">
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {lead.archive
                        ? t("common.unarchive")
                        : t("common.archive")}
                    </Tooltip>
                  }
                >
                  <Button
                    size="sm"
                    className="me-2"
                    variant={`outline-${lead.archive ? "primary" : "danger"}`}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      setDependentStates({ selectedLead: lead });
                      toggleConfirmationDialog(lead);
                    }}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      icon={lead.archive ? faTrashCanArrowUp : faBoxArchive}
                    />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>{t("sync")}</Tooltip>}>
                  <Button
                    size="sm"
                    className="me-2"
                    variant="outline-success"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      toggleSyncDialog(lead);
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faSync} />
                  </Button>
                </OverlayTrigger>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleDrawer(lead._id);
                  }}
                >
                  <FontAwesomeIcon size="sm" icon={faEye} />
                </Button>
              </TableDetailCell>
            </TableRow>
          </React.Fragment>
        ))
      ) : (
        <NoResultsRow lengthOfColumns="7" />
      )}
      <Drawer
        showDrawer={showDrawer}
        toggleDrawer={toggleDrawer}
        drawerTitle={drawerDetails?.leadDetails?.leadName}
        isTestMode={drawerDetails?.testMode}
      >
        {isLoadingLead ? (
          <Loader />
        ) : (
          <div>
            <div className="d-flex align-items-center mb-3">
              <Button
                as={Link}
                target="_blank"
                className={`me-2 ${!drawerDetails?.funnelId && "disabled"}`}
                to={`/dashboard/funnels/${drawerDetails?.funnelId?._id}/form?funnelType=${drawerDetails?.funnelId?.general?.funnelType}`}
              >
                {t("view")} Funnel
              </Button>
              <Button
                as={Link}
                target="_blank"
                className={!drawerDetails?.funnelCustomer && "disabled"}
                to={`/dashboard/customers/${drawerDetails?.funnelCustomer}/overview`}
              >
                {t("view")} {t("common.customer")}
              </Button>
            </div>
            {drawerDetails?.leadService && drawerDetails?.leadQualified && (
              <div
                onClick={toggleSendBackToAgentConfirmation}
                className="d-inline-block fw-bold text-primary border-bottom border-primary cursor-pointer mb-3"
              >
                {t("sendBackToAgentTitle")}
              </div>
            )}
            <div className="mb-4">
              <h6 className="my-2 fw-bold">Lead:</h6>
              <CustomCard>
                <Accordion alwaysOpen activeKey={1}>
                  <AccordionItem className="border-0" eventKey={1}>
                    <Accordion.Header>Details</Accordion.Header>
                    <Accordion.Body className="bg-white rounded-bottom">
                      <CardDataContainer
                        collection={
                          drawerDetails?.adDetails
                            ? [...collections, ...adDetailsCollection]
                            : collections
                        }
                      />
                    </Accordion.Body>
                  </AccordionItem>
                </Accordion>
              </CustomCard>
            </div>
            <div className="mb-4">
              <h6 className="my-2 fw-bold">Integration(s):</h6>
              <CustomCard>
                <Accordion alwaysOpen activeKey={2}>
                  <AccordionItem className="border-0" eventKey={2}>
                    <Accordion.Header>Details</Accordion.Header>
                    <Accordion.Body className="bg-white rounded-bottom pb-1">
                      {drawerDetails?.syncData.length ? (
                        drawerDetails.syncData.map(
                          (_syncData, syncDataIndex) => (
                            <CustomCard
                              key={syncDataIndex}
                              className="mb-3 p-2 position-relative"
                            >
                              {_syncData?.integrationId?.deleted && (
                                <Badge className="bg-danger position-absolute top-0 end-0 mt-2 me-2">
                                  <FontAwesomeIcon
                                    className="me-1"
                                    icon={faExclamationTriangle}
                                  />
                                  {t("common.deleted")}!
                                </Badge>
                              )}
                              <CardDataContainer
                                collection={[
                                  {
                                    label: "Integration",
                                    value:
                                      generateIntegrationType(_syncData) ===
                                      INTEGRATION_TYPE.CONTACT_CLOUD
                                        ? generateContactCloudUrl({
                                            meta: _syncData?.integrationId
                                              ?.sourceData.meta
                                          })
                                        : _syncData?.integrationId?.sourceData
                                            .id
                                  },
                                  {
                                    label: t("common.type"),
                                    value: generateIntegrationType(_syncData)
                                  },
                                  {
                                    label: t("common.description"),
                                    value:
                                      _syncData?.integrationId?.description ||
                                      "---"
                                  },
                                  {
                                    label: t("common.syncStatus"),
                                    value:
                                      _syncData.status === SUCCESS ? (
                                        <Badge bg="success">
                                          {_syncData.status}
                                        </Badge>
                                      ) : (
                                        <Badge bg="danger">{FAIL}</Badge>
                                      )
                                  },
                                  {
                                    label: "Nachrichten",
                                    value: _syncData.messages.map(
                                      (msg, msgIdx) => (
                                        <div
                                          key={msgIdx}
                                          className="border-start border-2"
                                        >
                                          <CardDataContainer
                                            collection={[
                                              {
                                                label: "Text",
                                                value: msg.text || "---"
                                              },
                                              {
                                                label: "Time",
                                                value: msg.timestamp
                                                  ? generateDateTime(
                                                      msg.timestamp
                                                    )
                                                  : "---"
                                              }
                                            ]}
                                          />
                                        </div>
                                      )
                                    )
                                  }
                                ]}
                              />
                            </CustomCard>
                          )
                        )
                      ) : (
                        <div className="mb-3 fw-bold">{t("notSynced")}</div>
                      )}
                    </Accordion.Body>
                  </AccordionItem>
                </Accordion>
              </CustomCard>
            </div>
            <div className="mb-4">
              <h6 className="mb-2 fw-bold">Responses:</h6>
              <CustomCard>
                <Accordion alwaysOpen activeKey={3}>
                  <AccordionItem className="border-0" eventKey={3}>
                    <Accordion.Header>{t("questionnaire")}</Accordion.Header>
                    <Accordion.Body className="bg-white rounded-bottom pb-1">
                      {drawerDetails?.responses.length &&
                        drawerDetails.responses.map((qa, qaIndex) => (
                          <CustomCard key={qaIndex} className="mb-3 p-3">
                            <CardDataContainer
                              collection={[
                                {
                                  label: t("question", { count: 1 }),
                                  value: qa.qTitle
                                },
                                {
                                  label: `${t("common.answer")}(s)`,
                                  value: Array.isArray(qa.selectedAnswer) ? (
                                    <ListGroup numbered>
                                      {qa.selectedAnswer.map(
                                        (ansTxt, ansIndex) => (
                                          <ListGroup.Item
                                            key={ansIndex}
                                            className="border-0 p-0"
                                          >
                                            {ansTxt}
                                          </ListGroup.Item>
                                        )
                                      )}
                                    </ListGroup>
                                  ) : (
                                    qa.selectedAnswer
                                  )
                                }
                              ]}
                            />
                          </CustomCard>
                        ))}
                    </Accordion.Body>
                  </AccordionItem>
                </Accordion>
              </CustomCard>
            </div>
            {leadFunnel && (
              <div className="mb-4">
                <h6 className="my-2 fw-bold">Funnel Details:</h6>
                <CustomCard>
                  <Accordion alwaysOpen activeKey={4}>
                    <AccordionItem className="border-0" eventKey={4}>
                      <Accordion.Header>
                        {leadFunnel.general.funnelName}
                        <FunnelTypeBadge
                          className="ms-3"
                          btnbg={`--${leadFunnel.general.funnelType.toLowerCase()}-badge-bg`}
                          btncolor={`--${leadFunnel.general.funnelType.toLowerCase()}-color`}
                        >
                          {leadFunnel.general.funnelType}
                        </FunnelTypeBadge>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white rounded-bottom">
                        <div className="fw-bold mb-3">
                          {t("common.companyInfo")}
                        </div>
                        <CardDataContainer
                          collection={[
                            {
                              label: t("common.name"),
                              value: leadFunnel.companyInfo.companyName || "N/A"
                            },
                            {
                              label: t("common.country"),
                              value:
                                countryOptions.find(
                                  ({ _id }) =>
                                    _id === leadFunnel.companyInfo.country
                                ).name || "N/A"
                            }
                          ]}
                        />
                      </Accordion.Body>
                    </AccordionItem>
                  </Accordion>
                </CustomCard>
              </div>
            )}
            <div>
              <h6 className="mb-2 fw-bold">{t("leadActivities.title")}:</h6>
              <CustomCard>
                <Accordion alwaysOpen activeKey={5}>
                  <AccordionItem className="border-0" eventKey={5}>
                    <Accordion.Header>
                      {`${t("common.lead")} ${t("leadActivities.title")}`}
                    </Accordion.Header>
                    <Accordion.Body className="bg-white rounded-bottom">
                      <LeadActivities activities={activities} />
                    </Accordion.Body>
                  </AccordionItem>
                </Accordion>
              </CustomCard>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};
export default LeadsTableRow;
