import Filter from "./filter";
import { Col, Row, Button, Collapse, Card } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import { faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  statusOptions,
  dateRangeOptions,
  fbDateRangeOptions,
  formTypeOptions,
  sourceOptions,
  userRoleOptions,
  userStatusOptions,
  fbSortOptions,
  fbSortOrderOptions
} from "./static-filter-opts";
import { DATE_FORMAT } from "../strings";
import SelectField from "../reusable-form-fields/select-field";
import { showWarning } from "../../services/toast-service";
import { useSearchParams } from "react-router-dom";
import AsyncSelectField from "../reusable-form-fields/async-select";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import SelectStructure from "./select-structure";
import CheckBoxSwitch from "../reusable-form-fields/checkbox-switch";
import { useTranslation } from "react-i18next";
import DateRange from "./date-range";
import MinMaxRangeFilter from "./min-max-range-filter";

const CardContainer = styled(Card)`
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;
const FilterHeader = styled.div`
  padding: 12px;
  color: var(--secondary-text);
  transition: 0.5s all ease-in-out;
  &:hover {
    color: #000;
  }
`;
const CustomBadge = styled.div`
  top: -10px;
  left: -8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  background: #1b8754;
`;
const FilterForm = styled.form`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;
const defaultActiveValues = {
  customer: false,
  funnel: false,
  tags: false,
  category: false,
  from: false,
  to: false,
  min: false,
  max: false,
  dateRange: false,
  status: false,
  formType: false,
  source: false,
  archive: false,
  role: false,
  userStatus: false,
  leadsource: false,
  leadstatus: false,
  sortby: false,
  sortorder: false
};

const AdvancedFilters = ({
  components = [],
  pageType,
  pageName,
  pageNameList,
  btnText,
  placeholder,
  isModalRequired,
  isResourceTypeAvailable,
  hideAddNew,
  handleModal,
  downloadLeadsButton = false,
  isDownloadLeadsButtonDisabled = false
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [isCollpased, setIsCollpased] = useState(false);
  const [active, setActive] = useState(defaultActiveValues);
  const statusParams = statusOptions.find(
    (el) => el._id === searchParams.get("status")
  );
  const dateRangeParam = (
    pageType === "fbAds" ? fbDateRangeOptions : dateRangeOptions
  ).find((el) => el._id === searchParams.get("dateRange"));
  const formTypeParam = formTypeOptions.find(
    (el) => el._id === searchParams.get("formType")
  );
  const sourceParam = sourceOptions.find(
    (el) => el._id === searchParams.get("source")
  );
  const from = searchParams.get("from")
    ? new Date(searchParams.get("from"))
    : "";
  const to = searchParams.get("to") ? new Date(searchParams.get("to")) : "";
  const roleParam = userRoleOptions.find(
    (el) => el._id === searchParams.get("role")
  );
  const userStatusParam = userStatusOptions.find(
    (el) => el._id === searchParams.get("userStatus")
  );
  const leadSource = sourceOptions.find(
    (el) => el._id === searchParams.get("leadsource")
  );
  const leadStatus = statusOptions.find(
    (el) => el._id === searchParams.get("leadstatus")
  );
  const sortByParam = fbSortOptions.find(
    (el) => el._id === searchParams.get("sortby")
  );
  const sortOrderParam = fbSortOrderOptions.find(
    (el) => el._id === searchParams.get("sortorder")
  );
  const minParam = searchParams.get("min");
  const maxParam = searchParams.get("max");

  const methods = useForm({
    defaultValues: {
      status: statusParams,
      dateRange: dateRangeParam,
      from,
      to,
      formType: formTypeParam,
      source: sourceParam,
      archive: searchParams.get("archive") === "true" ? true : false || null,
      role: roleParam,
      userStatus: userStatusParam,
      leadsource: leadSource,
      leadstatus: leadStatus,
      sortby: sortByParam,
      sortorder: sortOrderParam,
      min: minParam,
      max: maxParam
    }
  });
  const { reset, setValue, control, register, handleSubmit } = methods;
  const {
    handleCustomerOptions,
    handleCategoryOptions,
    handleFunnelOptions,
    handleTagOptions
  } = useOptionsFetch({
    setValue
  });

  const handleDeleteParam = (paramName) => {
    searchParams.delete(paramName);
    setSearchParams(searchParams);
  };

  const searchFilter = (values) => {
    const startDate = moment(values.from, DATE_FORMAT);
    const endDate = moment(values.to, DATE_FORMAT);
    if (
      startDate &&
      endDate &&
      !startDate.isSame(endDate) &&
      startDate.isAfter(endDate)
    ) {
      showWarning(t("endDateWarning"));
      return;
    }
    const formValues = { ...values };
    if ((formValues.to || formValues.from) && formValues.dateRange) {
      if (isCustomDate) {
        formValues.dateRange = null;
      } else {
        formValues.from = null;
        formValues.to = null;
      }
    }
    if (Number(formValues.min) > Number(formValues.max)) {
      showWarning(t("minmaxRangeWarning"));
      return;
    }
    if (formValues.dateRange?._id === "custom") {
      formValues.dateRange = null;
    }
    for (let key in formValues) {
      const value = formValues[key];
      if (!value) {
        searchParams.delete(key);
        setSearchParams(searchParams);
        setActive((props) => {
          const clone = { ...props };
          return {
            ...clone,
            [key]: false
          };
        });
      }
      if (value) {
        if (Array.isArray(value)) {
          const params = [];
          value.forEach((param) => {
            params.push(param.value || param._id);
          });
          if (!params.length) {
            searchParams.delete(key);
          } else {
            searchParams.set(key, JSON.stringify(params));
          }
        } else {
          if (key === "from" || key === "to") {
            let dateValue = moment(value, DATE_FORMAT);
            if (dateValue?.isValid()) {
              dateValue = moment(dateValue).format(DATE_FORMAT);
              searchParams.set(key, dateValue);
            } else {
              showWarning(t("wrongDate"));
            }
          } else if (typeof value === "boolean" || typeof value === "string") {
            searchParams.set(key, value);
          } else {
            searchParams.set(key, value._id);
          }
        }
        if (Array.isArray(value)) {
          setActive((props) => {
            const clone = { ...props };
            return {
              ...clone,
              [key]: value.length ? true : false
            };
          });
        } else {
          setActive((props) => {
            const clone = { ...props };
            return {
              ...clone,
              [key]: true
            };
          });
        }
      } else if (typeof value === "boolean") {
        clearFilter(key);
      }
    }
    searchParams.set("pageIndex", 1);
    setSearchParams(searchParams);
  };
  useEffect(() => {
    if (!searchParams.toString()) {
      setActive(defaultActiveValues);
      reset({
        tags: null,
        customer: null,
        funnel: null,
        category: null,
        from: null,
        to: null,
        dateRange: null,
        status: null,
        formType: null,
        source: null,
        archive: null,
        role: null,
        userStatus: null
      });
    } else {
      for (const entry of searchParams.entries()) {
        const [param] = entry;
        if (Object.keys(defaultActiveValues).includes(param)) {
          setActive((props) => {
            const clone = { ...props };
            return {
              ...clone,
              [param]: true
            };
          });
        }
      }
    }
  }, [reset, searchParams]);
  const clearFilter = (filterProperty) => {
    handleDeleteParam(filterProperty);
    searchParams.set("pageIndex", 1);
    setSearchParams(searchParams);
    setActive({ ...active, [filterProperty]: false });
    setValue(filterProperty, null);
  };
  const fetchData = {
    customer: handleCustomerOptions,
    category: handleCategoryOptions,
    funnel: handleFunnelOptions,
    tags: handleTagOptions
  };
  const hideCustomDate = () => {
    const dateRangeParam = (
      pageType === "fbAds" ? fbDateRangeOptions : dateRangeOptions
    ).find((el) => el._id === searchParams.get("dateRange"));
    if (dateRangeParam) {
      setValue("dateRange", dateRangeParam);
    } else {
      setValue("dateRange", null);
    }
    setIsCustomDate(false);
  };
  return (
    <>
      <div
        className={`${
          Object.values(active).includes(true)
            ? "border-success"
            : "border-secondary"
        }`}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <CardContainer
            role="button"
            onClick={() => setIsCollpased(!isCollpased)}
          >
            <FilterHeader>
              {Object.values(active).includes(true) && <CustomBadge />}
              <div
                className={
                  Object.values(active).includes(true)
                    ? "text-success fw-bold"
                    : ""
                }
              >
                <FontAwesomeIcon icon={faFilter} className="me-2" />
                {t("advancedFilter.title")}
              </div>
            </FilterHeader>
          </CardContainer>
          <Filter
            pageType={pageType}
            pageName={pageName}
            pageNameList={pageNameList}
            btnText={btnText}
            placeholder={placeholder}
            isModalRequired={isModalRequired}
            isResourceTypeAvailable={isResourceTypeAvailable}
            hideAddNew={hideAddNew}
            handleModal={handleModal}
            downloadLeadsButton={downloadLeadsButton}
            isDownloadLeadsButtonDisabled={isDownloadLeadsButtonDisabled}
          />
        </div>
        <Collapse in={isCollpased}>
          <div>
            {/* <CardContainer> */}
            <FilterForm
              onSubmit={handleSubmit(searchFilter)}
              className="py-3 px-2"
            >
              <div>
                <Row>
                  <FormProvider {...methods}>
                    {components.map(
                      ({
                        type,
                        label,
                        name,
                        options = [],
                        getOptionLabel,
                        paramName,
                        isMulti,
                        queryName
                      }) => {
                        if (type === "asyncFilter") {
                          return (
                            <SelectStructure
                              key={name}
                              label={t(label)}
                              active={active[name]}
                              activeKey={name}
                              clearFilter={clearFilter}
                            >
                              <AsyncSelectField
                                control={control}
                                name={name}
                                paramName={paramName}
                                fetchData={fetchData[name]}
                                getOptionLabel={getOptionLabel}
                                isMulti={isMulti}
                                queryName={queryName}
                                capitalized={false}
                              />
                            </SelectStructure>
                          );
                        } else if (type === "checkBox") {
                          return (
                            <SelectStructure
                              key={name}
                              label={t(label)}
                              activeKey={name}
                            >
                              <CheckBoxSwitch
                                control={control}
                                name={name}
                                register={register}
                              />
                            </SelectStructure>
                          );
                        } else if (type === "dateRangeFilter") {
                          return (
                            <DateRange
                              hideCustomDate={hideCustomDate}
                              isCustomDate={isCustomDate}
                              setIsCustomDate={setIsCustomDate}
                              active={{
                                [name]: active[name],
                                from: active.from,
                                to: active.to
                              }}
                              clearFilter={clearFilter}
                              setActive={setActive}
                              key={name}
                              name={name}
                              fromName="from"
                              toName="to"
                              inputFormat={"yyyy-MM-dd"}
                              outputFormat={DATE_FORMAT}
                              label={t(label)}
                            />
                          );
                        } else if (type === "fbDateRangeFilter") {
                          return (
                            <DateRange
                              hideCustomDate={hideCustomDate}
                              pageType={pageType}
                              isCustomDate={isCustomDate}
                              setIsCustomDate={setIsCustomDate}
                              active={{
                                [name]: active[name],
                                from: active.from,
                                to: active.to
                              }}
                              clearFilter={clearFilter}
                              setActive={setActive}
                              key={name}
                              name={name}
                              fromName="from"
                              toName="to"
                              inputFormat={"yyyy-MM-dd"}
                              outputFormat={DATE_FORMAT}
                              label={t(label)}
                            />
                          );
                        } else if (type === "minMaxRangeFilter") {
                          return (
                            <MinMaxRangeFilter
                              key={name}
                              label={t(label)}
                              active={{ min: active.min, max: active.max }}
                              clearFilter={clearFilter}
                              register={register}
                            />
                          );
                        }
                        return (
                          <SelectStructure
                            key={name}
                            label={t(label)}
                            activeKey={name}
                            active={active[name]}
                            clearFilter={clearFilter}
                          >
                            <SelectField
                              control={control}
                              name={name}
                              options={options}
                            />
                          </SelectStructure>
                        );
                      }
                    )}
                  </FormProvider>
                  <Col className="text-end">
                    <Button type="submit`">
                      <FontAwesomeIcon className="me-2" icon={faSearch} />
                      {t("advancedFilter.search")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </FilterForm>
            {/* </CardContainer> */}
          </div>
        </Collapse>
      </div>
    </>
  );
};
export default AdvancedFilters;
