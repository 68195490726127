import * as d3 from "d3";
import { amountFormatter, INSIGHT_TYPE } from "./analytics-helper";

export class SiteVisitsChart {
  QUESTION_DESCRIPTION_Y = 30;
  QUESTION_SYMBOL_SIZE = 140;
  QUESTION_SYMBOL_X = 6;
  QUESTION_TITLE_X = this.QUESTION_SYMBOL_X + 15;
  QUESTION_TITLE_Y = 5;
  NAVIGATION_SYMBOL_SIZE = 50;
  NAVIGATION_SYMBOL_GAP = 5;
  BAR_WIDTH = 40;
  NETWORK_LINE_GAP = 8;
  NETWORK_LEFT_GAP = 28;
  PERCENT_CONTAINER_HEIGHT = 20;
  COUNT_TEXT_DIFFERENCE = 40;
  INDIVIDUAL_COUNT_TEXT_WIDTH = 34;
  INDIVIDUAL_COUNT_TEXT_HEIGHT = 12;
  LINE_Y_MAX = 20;
  MARGIN = {
    top: 40,
    right: 20,
    left: 20,
    bottom: 70 + this.PERCENT_CONTAINER_HEIGHT
  };
  //----------Answers--------
  ANSWERS_START_POINT = 100;
  ANSWERS_MARGIN_TOP = 40;
  ANSWER_COUNT_PERCENT_Y = -5;
  ANSWER_COUNT_PERCENT_X = 35 + this.MARGIN.right;
  ANSWERS_OPTIONS_PADDING = 45;
  ANSWERS_COUNT_TEXT_WIDTH = 72;
  ANSWERS_GAP = 5;
  ANSWERS_BAR_HEIGHT = 20;
  ANSWERS_IMAGE_Y = 20;
  ANSWERS_TITLE_Y = -11;
  ANSWERS_OPTION_PAD = 4;
  ANSWERS_PAD = 10;
  ANSWERS_CONTAINER = 240;
  TOOLTIP_Y_AXIS = 11;
  TOOLTIP_X_AXIS = 150;
  ANSWERS_CONTAINER_OVERLAP = 60 + this.ANSWERS_PAD + this.MARGIN.right;
  ANSWER_BAR_X =
    this.ANSWERS_OPTION_PAD + this.ANSWERS_BAR_HEIGHT + this.ANSWERS_OPTION_PAD;
  ANSWERS_BAR_WIDTH =
    this.ANSWERS_CONTAINER -
    this.ANSWERS_PAD -
    this.MARGIN.right -
    this.ANSWER_BAR_X -
    this.ANSWERS_COUNT_TEXT_WIDTH -
    this.ANSWERS_OPTION_PAD;
  ANSWER_COUNT_X =
    this.ANSWER_BAR_X + this.ANSWERS_BAR_WIDTH + this.ANSWERS_OPTION_PAD;
  chart = undefined;
  svg = undefined;
  svgG = undefined;
  tooltip = undefined;
  questionHeading = undefined;

  constructor(chartRef) {
    this.chart = d3.select(chartRef);
    this.svg = this.chart.append("svg");
    this.svgG = this.svg.append("g");
    this.questionHeading = this.chart
      .append("div")
      .attr("class", "question-heading");
    this.tooltip = this.chart.append("div").attr("class", "chart-tooltip");
  }

  draw(dataState, clientWidth) {
    const width = clientWidth;
    const height = 300;

    // --------==---------------==------------
    const nodeById = new Map(
      dataState.map((mp) => {
        mp.answers = mp.answers || [];
        const answersCount = mp.answers.reduce((sum, m) => sum + m.count, 0);
        mp.answersCount = answersCount;
        mp.answers = mp.answers.map((m) => ({
          id: mp.id,
          type: mp.type,
          answersCount,
          ...m
        }));
        mp.sourceNodes = [];
        return [mp.id, mp];
      })
    );
    dataState.forEach((mp) => {
      if (mp.targets) {
        mp.targetNodes = mp.targets.map((t) => {
          const targetNode = nodeById.get(t.id);
          targetNode.sourceNodes.push(mp);
          return targetNode;
        });
      }
    });

    const maxRange = Math.max(...dataState.map(({ count }) => count), 1);

    // Y axis
    const graphYScale = d3
      .scaleLinear()
      .domain([0, maxRange])
      .range([0, height]);

    // X axis
    const graphXScale = d3
      .scaleBand()
      .range([
        this.MARGIN.left + this.MARGIN.right + this.ANSWERS_CONTAINER,
        width - this.MARGIN.left - this.MARGIN.right
      ])
      .domain(dataState.map((d) => d.id))
      .padding(1);

    const maximumHistoryCount = Math.max(
      ...dataState.map(({ targets }) => targets?.length || 0),
      0
    );

    const maximumHeight =
      height +
      this.MARGIN.top +
      this.ANSWERS_MARGIN_TOP +
      (maximumHistoryCount + 1) * this.NETWORK_LINE_GAP;

    this.svg.attr("height", maximumHeight + this.MARGIN.bottom);

    this.svgG.attr(
      "transform",
      `translate(${this.MARGIN.left}, ${
        maximumHeight + this.PERCENT_CONTAINER_HEIGHT
      })`
    );

    this.svgG
      .append("g")
      .attr("class", "left-scale")
      .call(
        d3.axisBottom(graphXScale).tickFormat((d) => nodeById.get(d).label)
      );

    const that = this;
    //Bar
    this.svgG
      .selectAll("g.data-element")
      .data(dataState)
      .join("g")
      .attr("class", (d) => `data-element fill-color analytic-${d.type}`)
      .call((dataG) =>
        dataG
          .append("g")
          .attr("class", "clickable-node")
          .call((clickableNodeG) => {
            // Bar-Line
            clickableNodeG
              .append("rect")
              .attr("class", "bar")
              .attr("width", this.BAR_WIDTH)
              .attr("x", (d) => graphXScale(d.id) - this.BAR_WIDTH / 2)
              .attr("y", (d) => -graphYScale(d.count))
              .attr("height", (d) => graphYScale(d.count));
          })
          .call((clickableNodeG) =>
            //Count-Text
            clickableNodeG
              .append("text")
              .attr("class", "count-text")
              .text((d) => amountFormatter(d.count))
              .attr("x", (d) => graphXScale(d.id))
              .attr("y", graphYScale(0) + this.COUNT_TEXT_DIFFERENCE)
          )
          .call((clickableNodeG) =>
            //Count-Text
            clickableNodeG
              .append("text")
              .attr("class", "loss-count-text")
              .text((d) => {
                let ansCount = d.answers.reduce(
                  (prev, curr) => prev + curr.count,
                  0
                );
                const targetsCount = d.targets.reduce(
                  (prev, curr) => prev + curr.count,
                  0
                );
                if (d.count - ansCount < 0) {
                  ansCount = d.count;
                }
                return d.type === "question"
                  ? ` | ${amountFormatter(d.count - ansCount)}`
                  : d.type === "zip" || d.type === "lead"
                    ? ` | ${amountFormatter(d.count - targetsCount)}`
                    : "";
              })
              .attr("x", (d) => graphXScale(d.id) + 4)
              .attr("y", graphYScale(0) + this.COUNT_TEXT_DIFFERENCE)
          )
          .call((clickableNodeG) =>
            // Percent-Bar
            clickableNodeG
              .filter((d) => d.label !== "Q#1")
              .append("rect")
              .attr("class", "bar-percent")
              .attr("width", this.BAR_WIDTH - 2)
              .attr("x", (d) => graphXScale(d.id) + 1 - this.BAR_WIDTH / 2)
              .attr(
                "y",
                (d) => 1 - graphYScale(d.count) - this.PERCENT_CONTAINER_HEIGHT
              )
              .attr("height", this.PERCENT_CONTAINER_HEIGHT)
          )
          .call((clickableNodeG) =>
            // Percent-Text
            clickableNodeG
              .filter((d) => d.label !== "Q#1")
              .append("text")
              .attr("class", "percent-text")
              .text((d) => `${d.percentCount}%`)
              .attr("x", (d) => graphXScale(d.id))
              .attr(
                "y",
                (d) =>
                  this.PERCENT_CONTAINER_HEIGHT / 2 -
                  graphYScale(d.count) -
                  this.PERCENT_CONTAINER_HEIGHT
              )
          )
          .on("mousedown", function (_, d) {
            // svg.selectAll("g.answers").remove();

            const nodeToShow = d3.select(this.parentElement);
            const isClassed = nodeToShow.classed("selected");
            toggleAnswer([]);
            that.svgG.selectAll(".selected").classed("selected", false);

            if (!isClassed) {
              toggleAnswer([d]);
              nodeToShow.classed("selected", true);
            }
          })
      );

    const toggleAnswer = (data) => {
      this.questionHeading.style("opacity", null);
      if (data.length) {
        const [{ title }] = data;
        const x = this.MARGIN.left + this.QUESTION_TITLE_X;
        this.questionHeading
          .data(data)
          .call((questionDiv) => this.drawTooltip(questionDiv, x))
          .style("left", `${x}px`)
          .style("top", `${this.MARGIN.top - 20}px`)
          .style("max-width", `${width - x - this.MARGIN.right}px`)
          .style("opacity", "1")
          .text(title);
      }
      drawAnswerPanel(data);
      drawAnalyticsLine(data);
    };

    const drawAnswerPanel = (data) => {
      this.svg
        .selectAll("g.answers")
        .data(data)
        .join("g")
        .attr("class", "answers")
        .attr(
          "transform",
          (d) =>
            `translate(${graphXScale(d.id) - this.BAR_WIDTH / 2}, ${
              maximumHeight - graphYScale(d.count)
            })scale(0.5)`
        )
        .call((answerG) => {
          // answerG.selectAll("g.answer-head").remove();
          answerG
            .append("g")
            .attr("class", "answer-head")
            .attr("transform", `translate(${this.MARGIN.left}, 0)`)
            .call((dataG) => {
              const questionSymbol = d3
                .symbol()
                .size(this.QUESTION_SYMBOL_SIZE)
                .type(d3.symbolSquare);
              dataG
                .append("path")
                .attr("class", `question-point`)
                .attr("d", questionSymbol)
                .attr("transform", `translate(${this.QUESTION_SYMBOL_X}, 0)`);
            })
            // .call((dataG) =>
            //   dataG
            //     .append("text")
            //     .attr("class", "question-text")
            //     .text((d) => d.title)
            //     .attr("x", this.QUESTION_TITLE_X)
            //     .attr("y", this.QUESTION_TITLE_Y)
            // )
            .call((dataG) =>
              dataG
                .filter((d) => d.type === INSIGHT_TYPE.QUESTION)
                .append("text")
                .attr("class", "question-desc")
                .text(
                  (d) =>
                    `${amountFormatter(d.answersCount)} von ${amountFormatter(
                      d.count
                    )} Besucher haben auf diese Frage geantwortet`
                )
                .attr("y", this.QUESTION_DESCRIPTION_Y)
            );
        })
        .call((answerG) => {
          // answerG.selectAll("g.answer-body").remove();
          answerG
            .append("g")
            .attr("class", "answer-body")
            .attr(
              "transform",
              `translate(${this.ANSWER_COUNT_PERCENT_X}, ${
                this.QUESTION_DESCRIPTION_Y + this.ANSWERS_OPTIONS_PADDING
              })`
            )
            .selectAll("g")
            .data((d) => d.answers)
            .join("g")
            .attr("class", "individual-answer")
            .attr(
              "transform",
              (_, idx) =>
                `translate(0, ${
                  (this.ANSWERS_BAR_HEIGHT + this.ANSWERS_GAP) * idx
                })`
            )
            .call((answersG) => this.drawTooltip(answersG))
            .call((answersG) =>
              answersG
                .append("text")
                .attr("class", "answer-percent-text")
                .attr("y", this.ANSWER_COUNT_PERCENT_Y)
                .text(
                  (d) =>
                    `${Math.round((d.count * 100) / (d.answersCount || 1))}%`
                )
            )
            .call((answersG) => {
              answersG
                .filter((f) => f.asset)
                .append("image")
                .attr("xlink:href", (d) => `${d.asset.url}/${d.asset.key}`);

              answersG.filter((f) => !f.asset).append("rect");

              answersG
                .selectAll("image, rect")
                .attr("class", "answer-img")
                .attr("y", -this.ANSWERS_IMAGE_Y)
                .attr("x", this.ANSWERS_OPTION_PAD)
                .attr("height", this.ANSWERS_BAR_HEIGHT)
                .attr("width", this.ANSWERS_BAR_HEIGHT);
            })

            .call((answersG) =>
              answersG
                .append("rect")
                .attr("class", "answer-bar-shadow")
                .attr("width", this.ANSWERS_BAR_WIDTH)
                .attr("y", -this.ANSWERS_BAR_HEIGHT)
                .attr("x", this.ANSWER_BAR_X)
                .attr("height", this.ANSWERS_BAR_HEIGHT)
            )
            .call((answersG) =>
              answersG
                .append("rect")
                .attr("class", "answer-bar")
                .attr(
                  "width",
                  (d) =>
                    this.ANSWERS_BAR_WIDTH * (d.count / (d.answersCount || 1))
                )
                .attr("y", -this.ANSWERS_BAR_HEIGHT)
                .attr("x", this.ANSWER_BAR_X)
                .attr("height", this.ANSWERS_BAR_HEIGHT)
            )
            .call((answersG) =>
              answersG
                .append("text")
                .attr("class", "answer-bar-text")
                .attr("y", this.ANSWERS_TITLE_Y)
                .attr("x", this.ANSWER_BAR_X + this.ANSWERS_OPTION_PAD)
                .text((d) => this.elipseAnswerText(d.title))
            )
            .call((answersG) =>
              answersG
                .append("text")
                .attr("class", "answer-count-text")
                .attr("y", this.ANSWER_COUNT_PERCENT_Y)
                .attr("x", this.ANSWER_COUNT_X)
                .text((d) => `${amountFormatter(d.count)} Antworten`)
            );
        })
        .transition()
        .attr("transform", `translate(0, ${this.MARGIN.top})scale(1)`);
    };

    const drawAnalyticsLine = (data) => {
      let networkLinks = [];
      if (data[0]) {
        networkLinks = this.generateNetworkLinks({
          data,
          networkLinks,
          graphXScale,
          graphYScale
        });
      }
      const analyticNetworkG = this.svg
        .selectAll("g.network-lines")
        .data(data)
        .join("g")
        .attr("class", "network-lines")
        .attr("transform", `translate(${this.MARGIN.left}, ${maximumHeight})`)
        .selectAll("g.analytic-network")
        .data(networkLinks)
        .join("g")
        .attr(
          "class",
          (d) =>
            `analytic-network stroke-color analytic-${d.target.type} network-target-link-${d.target.id} network-source-link-${d.source.id}`
        );

      analyticNetworkG
        .append("path")
        .attr("class", "network-line")
        .attr(
          "d",
          (d) =>
            `M${d.lineX1},${-d.lineY1} L${d.lineX1},${
              -d.lineYMax - this.LINE_Y_MAX
            } L${d.lineX2},${-d.lineYMax - this.LINE_Y_MAX} L${
              d.lineX2
            },${-d.lineY2}`
        );

      const circleSymbol = d3
        .symbol()
        .size(this.NAVIGATION_SYMBOL_SIZE)
        .type(d3.symbolCircle);

      analyticNetworkG
        .append("rect")
        .attr(
          "class",
          (d) =>
            `count-circle data-element fill-color analytic-${d.target.type}`
        )
        .attr(
          "transform",
          (d) =>
            `translate(${
              (d.lineX1 + d.lineX2) / 2 - this.INDIVIDUAL_COUNT_TEXT_WIDTH / 2
            }, ${
              -d.lineYMax -
              this.LINE_Y_MAX -
              this.INDIVIDUAL_COUNT_TEXT_HEIGHT / 2
            })`
        )
        .attr("width", this.INDIVIDUAL_COUNT_TEXT_WIDTH)
        .attr("height", this.INDIVIDUAL_COUNT_TEXT_HEIGHT);

      analyticNetworkG
        .append("text")
        .attr("class", "count-text")
        .text((d) => amountFormatter(d.count))
        .attr(
          "transform",
          (d) =>
            `translate(${(d.lineX1 + d.lineX2) / 2}, ${
              -d.lineYMax - this.LINE_Y_MAX - 1
            })`
        );

      analyticNetworkG
        .append("path")
        .attr("class", "line-point")
        .attr("d", circleSymbol)
        .attr(
          "transform",
          (d) =>
            `translate(${d.lineX1}, ${-d.lineY1 - this.NAVIGATION_SYMBOL_GAP})`
        );

      const triSymbol = d3
        .symbol()
        .size(this.NAVIGATION_SYMBOL_SIZE)
        .type(d3.symbolTriangle);

      analyticNetworkG
        .append("path")
        .attr("class", "line-point")
        .attr("d", triSymbol)
        .attr(
          "transform",
          (d) =>
            `translate(${d.lineX2}, ${
              -d.lineY2 - this.NAVIGATION_SYMBOL_GAP
            })rotate(60)`
        );

      // analyticNetworkG
      //   .selectAll("g.network-lines")
      //   .transition()
      //   .attr(
      //     "style",
      //     `translate(${width - this.ANSWERS_CONTAINER}, ${
      //       20 + this.MARGIN.top
      //     })scale(1)`
      //   );
    };
  }

  drawTooltip(hoverG, offsetX = 0) {
    hoverG
      .on("mouseover", (_, d) =>
        this.tooltip.style("visibility", "visible").text(d.title)
      )
      .on("mousemove", (e) =>
        this.tooltip
          .style("top", `${e.layerY}px`)
          .style("left", `${e.layerX + offsetX}px`)
      )
      .on("mouseout", () => this.tooltip.style("visibility", null));
  }

  generateNetworkLinks({ data, networkLinks, graphXScale, graphYScale }) {
    const [datum] = data;
    const allLinks = datum.sourceNodes.concat(datum.targetNodes);
    const halfWidth = ((allLinks.length - 1) * this.NETWORK_LINE_GAP) / 2;
    const sourceLinks = datum.sourceNodes.map((mp, idx) => {
      const target = mp.targets.find(({ id }) => datum.id === id);
      const link = {
        source: mp,
        target: datum
      };
      link.id = `${link.source.id}-${link.target.id}`;
      link.count = target.count;

      link.lineX1 = 0;
      link.lineX2 =
        allLinks.findIndex(({ id }) => id === link.source.id) *
          this.NETWORK_LINE_GAP -
        halfWidth;
      link.lineYMax = idx * this.NETWORK_LINE_GAP;
      return link;
    });

    const targetLinks = datum.targetNodes.map((mp, idx) => {
      const target = datum.targets.find(({ id }) => mp.id === id);
      const link = {
        source: datum,
        target: mp
      };
      link.id = `${link.source.id}-${link.target.id}`;
      link.count = target.count;

      link.lineX1 =
        allLinks.findIndex(({ id }) => id === link.target.id) *
          this.NETWORK_LINE_GAP -
        halfWidth;
      link.lineX2 = 0;
      link.lineYMax = idx * this.NETWORK_LINE_GAP;

      return link;
    });
    networkLinks = sourceLinks.concat(targetLinks);

    networkLinks.forEach((link) => {
      link.lineY1 = graphYScale(link.source.count);
      link.lineY2 = graphYScale(link.target.count);
      link.lineYMax += Math.ceil(Math.max(link.lineY1, link.lineY2));

      link.lineX1 += graphXScale(link.source.id);
      link.lineX2 += graphXScale(link.target.id);
      if (link.source.label === "Q#1") {
        link.lineY1 -= this.PERCENT_CONTAINER_HEIGHT;
      }
    });
    return networkLinks;
  }

  elipseAnswerText(str) {
    return str.length > 15 ? `${str.slice(0, 15)}...` : str;
  }

  destroy() {
    this.chart.selectChildren("*").remove();
    this.chart = undefined;
    this.svg = undefined;
  }
}
