import { useSearchParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import {
  CustomCard,
  PageHeading,
  HeaderColumn,
  BootstrapTable,
  LastHeaderColumn,
  FirstHeaderColumn
} from "../common-styling";
import LeadsTableRow from "./leads-table-row";
import TableLoader from "../loader/table-loader";
import ViewMoreButton from "../view-more-button";
import { getAllLeads } from "../../services/api-declaration";
import AdvancedFilters from "../table-filter/advanced-filter";
import CustomPagination from "../pagination/custom-pagination";
import { leadFilterConfig } from "../funnels-table/filter-config";
import { showError } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const LeadsTable = ({ customerId = null, asSubComponent = false, limit }) => {
  const { t } = useTranslation();
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchLeads = async () => {
    try {
      setIsLoading(true);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      if (customerId) {
        params.customer = customerId;
      }
      if (limit) {
        params.limit = limit;
      }
      const response = await getAllLeads(params);
      const data = response.data.data;
      setLeads(data.rows);
      setPaginateOpt({
        totalPages: data.totalPages,
        hasPrevPage: data.hasPrevPage,
        hasNextPage: data.hasNextPage,
        totalDocs: data.totalDocs,
        rowsOnPage: data.rows.length
      });
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", { what: "leads" })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const customersCb = useCallback(
    fetchLeads,
    // eslint-disable-next-line
    [searchParams, customerId, limit]
  );

  useEffect(() => {
    customersCb();
  }, [customersCb]);

  return (
    <div>
      <PageHeading className="">Leads</PageHeading>
      <div className="mt-3">
        {!asSubComponent && (
          <div>
            <AdvancedFilters
              components={leadFilterConfig}
              pageType=""
              heading="Leads"
              pageName="search"
              placeholder={t("userSearchPlaceholder")}
              btnText="Lead"
              isResourceTypeAvailable={false}
              hideAddNew
              downloadLeadsButton
              isDownloadLeadsButtonDisabled={
                !searchParams.get("funnel") || !paginateOpts.totalDocs
              }
            />
          </div>
        )}
      </div>
      <CustomCard className="my-3 p-3 rounded-0">
        <BootstrapTable responsive>
          <thead>
            <tr>
              <FirstHeaderColumn>{t("common.name")}</FirstHeaderColumn>
              <HeaderColumn className="text-center">
                {t("common.syncStatus")}
              </HeaderColumn>
              <HeaderColumn>Funnel / {t("common.customer")}</HeaderColumn>
              <HeaderColumn minWidth="230" className="text-center">
                {t("forwardedOnContactCloud")}
              </HeaderColumn>
              <HeaderColumn>
                {t("common.created")} {t("common.on")}
              </HeaderColumn>
              <HeaderColumn>{t("common.telephone")}</HeaderColumn>
              <HeaderColumn>{t("common.postalCode")}</HeaderColumn>
              <LastHeaderColumn empty></LastHeaderColumn>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoader colSpan={8} />
            ) : (
              <LeadsTableRow leads={leads} fetchLeads={fetchLeads} />
            )}
          </tbody>
        </BootstrapTable>
      </CustomCard>
      {!asSubComponent ? (
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      ) : (
        <ViewMoreButton
          id={customerId}
          path="../../../leads"
          searchParams={searchParams}
          resultsLength={leads.length}
          totalDocs={paginateOpts.totalDocs}
          handleQueryParams={handleQueryParams}
        />
      )}
    </div>
  );
};
export default LeadsTable;
